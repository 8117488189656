import React from 'react';

export default (blocks) => {
  return blocks.map(block => {
    const blockChildren = block.children.map(blockChild => {

      if (blockChild.marks.length > 0) {
        const matchingMark = block.markDefs.find(markDefs => markDefs._key === blockChild.marks[0])
        
        if (matchingMark._type === 'link') {
          return <span key={blockChild._key}><a href={matchingMark.href}>{blockChild.text}</a></span>
        }
      }

      return <span key={blockChild._key}>{blockChild.text}</span>;
    })

    return <p key={block._key}>{blockChildren}</p>
  })
}