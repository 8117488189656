import React from "react"
import { graphql } from 'gatsby'
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import buildPage from "../utilities/buildPage";

const ContactTitle = styled.h1`
  text-align: center;
`

const ContactBody = styled.div`
  max-width: 640px;
`

const Contact = props => (
  <Layout>
    <SEO title="Contact" />
    <ContactTitle>Contact</ContactTitle>

    <ContactBody>
      {buildPage(props.data.sanityPages._rawText)}
    </ContactBody>
  </Layout>
)

export default Contact

export const contactContent = graphql`
  query contactContent {
    sanityPages(title: {eq: "Contact"}) {
      title
      _rawText
    }
  }
`
